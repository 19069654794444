const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.user) {
    next();
    return;
  }
  next('/user/login');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.user) {
    next();
    return;
  }
  next('/user');
};

export const user = [
  {
    path: '/',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/user/layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/user/index.vue'),
      },
      {
        path: 'contracts',
        component: () => import('../views/user/pages/contracts.vue'),
      },
      {
        path: 'installations',
        component: () => import('../views/user/pages/installations.vue'),
      },
      {
        path: 'delivery',
        component: () => import('../views/user/pages/delivery.vue'),
      },

    ]
  },
 
  {
    path: 'login',
    component: () => import('../views/user/login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  
];
