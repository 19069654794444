import axios from 'axios';
// export const baseURL = 'http://localhost:4600';
export const baseURL = 'http://swarwest.com:4600';
export const baseUploadURL = baseURL + '/uploads/';
const admin = localStorage.admin ? JSON.parse(localStorage.admin) : '';

const user = localStorage.user ? JSON.parse(localStorage.user) : '';
export const HTTP = axios.create({
  baseURL: `${baseURL}/api/`,
  headers: {
    Authorization: `Bearer ${admin?.token ? admin?.token : user?.token}`,
  },
});
