import Vue from 'vue';

import moment from 'moment';

import { HTTP, baseUploadURL, baseURL } from './axios';

Vue.prototype.$baseUploadURL = baseUploadURL;

Vue.prototype.$http = HTTP;




Vue.prototype.$file2base64 = (
  ev: any,
  cb: (arg0: string | ArrayBuffer | null) => void,
) => {
  const file = ev.target.files[0];
  const reader = new FileReader();
  reader.onload = (e: ProgressEvent) => {
    const content = (e.target as FileReader).result;
    cb(content);
  };
  reader.readAsDataURL(file);
};

Vue.prototype.$toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


  Vue.prototype.$getStatus = (val: string) => {
    const list = {
      UnderDistribution: 'تحت التوزيع',
      UnderOperation: 'تحت التشغيل',
      UnderRevision: 'تحت المراجعه',
      RevisionDone:'تحت التسليم الفنى',
      backDelivery: 'مرتجع من السكيرتاريه',
      UnderDelivery: 'تحت التسليم',
      BackDelivery: 'ملاحظات تسليم العملاء',
      DeliveryDone: 'تم التسليم',
      DoneDeliveryCall: 'تم اغلاق العقد',
    };
    return list[val] ? list[val] : val;
  };
  
Vue.prototype.$errorHandler = (event: any) => {
  event.target.src = '/images/def.svg';
};

Vue.prototype.$checkRoles = (name: any) => {
  const user = JSON.parse(localStorage.admin);
  const roles = user.roles ?? JSON.parse(user.roles);
  if (roles.includes(name)) {
    return true;
  }
  return false;
};


Vue.prototype.$checkRolesTest = (name: any) => {
 
  return true;
};



Vue.prototype.$durationFormat = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('YYYY-MM-DD');
  } else {
    return '';
  }
};


Vue.prototype.$durationFormatFull = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('YYYY-MM-DD hh:mm A');
  } else {
    return '';
  }
};

Vue.prototype.$durationFormatFullDiff = (d1, d2) => {
  if (d1 && d2) {
    const days = moment(d2).diff(moment(d1), 'days');
    const hour = moment(d2).diff(moment(d1), 'hour') - days * 24;
    const minute =
      moment(d2).diff(moment(d1), 'minute') -
      moment(d2).diff(moment(d1), 'hour') * 60;

    return days + ' يوم <br>' + hour + ' ساعه <br>' + minute + ' دقيقه';
  } else {
    return 0;
  }
};

Vue.prototype.$eventHub = new Vue();
