import Vue from 'vue';
import { baseURL } from './axios';

Vue.prototype.$htmlPrint = (stylesHtml,prtHtml) => {
    prtHtml=prtHtml.replace(`<div class="table-header">`,`<div class="table-header"><img src="/logo.png" style="float: left;height: 50px;" />`);

  return `<!DOCTYPE html>
    <html>
      <head>

      ${stylesHtml}
        <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
        <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
        <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    <style>
    body{
      direction: rtl;
    }
     .p-sortable-column-icon , .p-filter-column{
     display: none;
     }

     .pi-chevron-right::before {
        content: "\e901";
        display: none;
    }

    .p-datatable .p-paginator-bottom {
        display: none;
    }

    .p-datatable .p-datatable-header {
        background: #495057;
        border: 1px solid #e9ecef;
            border-top-width: 1px;
            border-right-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
        border-width: 1px 0 1px 0;
        padding: 3rem 1rem;
        font-weight: 600;
        color: #f8f9fa;
    }


     </style>
     </head>
      <body>
      <div class="layout-main">
      <div class="p-grid">
        <div class="p-col-12">
          <div class="card">
            <div class="p-datatable-customers table table-striped p-datatable p-component p-datatable-hoverable-rows">
        ${prtHtml}
            </div>
          </div>
        </div>
      </div>
    </div>
      </body>
    </html>`;
};
