const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.admin) {
    next();
    return;
  }
  next('/admin/login');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.admin) {
    next();
    return;
  }
  next('/admin');
};

export const admin = [
  {
    path: '/',
    component: () => import('../views/admin/layout.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/',
        component: () => import('../views/admin/index.vue'),
      },

      {
        path: 'company',
        component: () => import('../views/admin/company/company.vue'),
      },
      {
        path: 'logs',
        component: () => import('../views/admin/company/logs.vue'),
      },

      {
        path: 'profile',
        component: () => import('../views/admin/users/admins/profile.vue'),
      },
      {
        path: 'circulars',
        component: () => import('../views/admin/company/circulars.vue'),
      },
      {
        path: 'notifications',
        component: () => import('../views/admin/company/notifications.vue'),
      },
      // System
      {
        path: 'system',
        component: () => import('../views/admin/system/system.vue'),
        children: [
          // branches
          {
            path: 'branches',
            component: () => import('../views/admin/system/branches/index.vue'),
          },
          {
            path: 'branches/add',
            component: () => import('../views/admin/system/branches/add.vue'),
          },
          {
            path: 'branches/edit/:id',
            component: () => import('../views/admin/system/branches/add.vue'),
          },

          // countries
          {
            path: 'countries',
            component: () =>
              import('../views/admin/system/countries/index.vue'),
          },
          {
            path: 'countries/add',
            component: () => import('../views/admin/system/countries/add.vue'),
          },
          {
            path: 'countries/edit/:id',
            component: () => import('../views/admin/system/countries/add.vue'),
          },
          // cities
          {
            path: 'cities',
            component: () => import('../views/admin/system/cities/index.vue'),
          },
          {
            path: 'cities/add',
            component: () => import('../views/admin/system/cities/add.vue'),
          },
          {
            path: 'cities/edit/:id',
            component: () => import('../views/admin/system/cities/add.vue'),
          },
          // currencies
          {
            path: 'currencies',
            component: () =>
              import('../views/admin/system/currencies/index.vue'),
          },
          {
            path: 'currencies/add',
            component: () => import('../views/admin/system/currencies/add.vue'),
          },
          {
            path: 'currencies/edit/:id',
            component: () => import('../views/admin/system/currencies/add.vue'),
          },
          // products
          {
            path: 'products',
            component: () => import('../views/admin/system/products/index.vue'),
          },
          {
            path: 'products/add',
            component: () => import('../views/admin/system/products/add.vue'),
          },
          {
            path: 'products/edit/:id',
            component: () => import('../views/admin/system/products/add.vue'),
          },
          {
            path: 'items/add/:productsId',
            component: () =>
              import('../views/admin/system/products/add_item.vue'),
          },
          {
            path: 'items/edit/:id',
            component: () =>
              import('../views/admin/system/products/add_item.vue'),
          },

          // surveys
          {
            path: 'surveys',
            component: () => import('../views/admin/system/surveys/index.vue'),
          },
          {
            path: 'surveys/add',
            component: () => import('../views/admin/system/surveys/add.vue'),
          },
          {
            path: 'surveys/edit/:id',
            component: () => import('../views/admin/system/surveys/add.vue'),
          },
          // administration
          {
            path: 'administration',
            component: () =>
              import('../views/admin/system/administration/index.vue'),
          },
          {
            path: 'administration/add',
            component: () =>
              import('../views/admin/system/administration/add.vue'),
          },
          {
            path: 'administration/edit/:id',
            component: () =>
              import('../views/admin/system/administration/add.vue'),
          },
          // sections
          {
            path: 'sections',
            component: () => import('../views/admin/system/sections/index.vue'),
          },
          {
            path: 'sections/add',
            component: () => import('../views/admin/system/sections/add.vue'),
          },
          {
            path: 'sections/edit/:id',
            component: () => import('../views/admin/system/sections/add.vue'),
          },
          // employees
          {
            path: 'employees',
            component: () =>
              import('../views/admin/system/employees/index.vue'),
          },
          {
            path: 'employees/add',
            component: () => import('../views/admin/system/employees/add.vue'),
          },
          {
            path: 'employees/edit/:id',
            component: () => import('../views/admin/system/employees/add.vue'),
          },
          // clientGroups
          {
            path: 'clientGroups',
            component: () =>
              import('../views/admin/system/client_groups/index.vue'),
          },
          {
            path: 'clientGroups/add',
            component: () =>
              import('../views/admin/system/client_groups/add.vue'),
          },
          {
            path: 'clientGroups/edit/:id',
            component: () =>
              import('../views/admin/system/client_groups/add.vue'),
          },
          // taxes
          {
            path: 'taxes',
            component: () => import('../views/admin/system/taxes/index.vue'),
          },
          {
            path: 'taxes/add',
            component: () => import('../views/admin/system/taxes/add.vue'),
          },
          {
            path: 'taxes/edit/:id',
            component: () => import('../views/admin/system/taxes/add.vue'),
          },
          // warehouse
          {
            path: 'warehouse',
            component: () =>
              import('../views/admin/system/warehouse/index.vue'),
          },
          {
            path: 'warehouse/add',
            component: () => import('../views/admin/system/warehouse/add.vue'),
          },
          {
            path: 'warehouse/edit/:id',
            component: () => import('../views/admin/system/warehouse/add.vue'),
          },
        ],
      },

      // inventory
      {
        path: 'inventory',
        component: () => import('../views/admin/inventory/layout.vue'),
        children: [
          // items_groups
          {
            path: 'itemsGroups',
            component: () =>
              import('../views/admin/inventory/files/items_groups/index.vue'),
          },
          {
            path: 'itemsGroups/add',
            component: () =>
              import('../views/admin/inventory/files/items_groups/add.vue'),
          },
          {
            path: 'itemsGroups/edit/:id',
            component: () =>
              import('../views/admin/inventory/files/items_groups/add.vue'),
          },
          // itemsTypes
          {
            path: 'itemsTypes',
            component: () =>
              import('../views/admin/inventory/files/items_types/index.vue'),
          },
          {
            path: 'itemsTypes/add',
            component: () =>
              import('../views/admin/inventory/files/items_types/add.vue'),
          },
          {
            path: 'itemsTypes/edit/:id',
            component: () =>
              import('../views/admin/inventory/files/items_types/add.vue'),
          },
          // itemsUnits
          {
            path: 'itemsUnits',
            component: () =>
              import('../views/admin/inventory/files/items_units/index.vue'),
          },
          {
            path: 'itemsUnits/add',
            component: () =>
              import('../views/admin/inventory/files/items_units/add.vue'),
          },
          {
            path: 'itemsUnits/edit/:id',
            component: () =>
              import('../views/admin/inventory/files/items_units/add.vue'),
          },
          // storeItems
          {
            path: 'storeItems',
            component: () =>
              import('../views/admin/inventory/files/store_items/index.vue'),
          },
          {
            path: 'storeItems/add',
            component: () =>
              import('../views/admin/inventory/files/store_items/add.vue'),
          },
          {
            path: 'storeItems/edit/:id',
            component: () =>
              import('../views/admin/inventory/files/store_items/add.vue'),
          },

          // openingBalance
          {
            path: 'openingBalance',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_opening_balance/index.vue'
              ),
          },
          {
            path: 'openingBalance/add',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_opening_balance/add.vue'
              ),
          },
          {
            path: 'openingBalance/edit/:id',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_opening_balance/add.vue'
              ),
          },

          // receivingInventory
          {
            path: 'receivingInventory',
            component: () =>
              import(
                '../views/admin/inventory/transactions/receiving_inventory/index.vue'
              ),
          },
          {
            path: 'receivingInventory/add',
            component: () =>
              import(
                '../views/admin/inventory/transactions/receiving_inventory/add.vue'
              ),
          },
          {
            path: 'receivingInventory/edit/:id',
            component: () =>
              import(
                '../views/admin/inventory/transactions/receiving_inventory/add.vue'
              ),
          },
          // inventoryStoreCountingReq

          {
            path: 'inventoryStoreCountingReq',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_store_counting_req/index.vue'
              ),
          },
          {
            path: 'inventoryStoreCountingReq/add',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_store_counting_req/add.vue'
              ),
          },
          {
            path: 'inventoryStoreCountingReq/edit/:id',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_store_counting_req/add.vue'
              ),
          },


            // inventoryStoreCounting

            {
              path: 'inventoryStoreCounting',
              component: () =>
                import(
                  '../views/admin/inventory/transactions/inventory_store_counting/index.vue'
                ),
            },
            {
              path: 'inventoryStoreCounting/add',
              component: () =>
                import(
                  '../views/admin/inventory/transactions/inventory_store_counting/add.vue'
                ),
            },
            {
              path: 'inventoryStoreCounting/edit/:id',
              component: () =>
                import(
                  '../views/admin/inventory/transactions/inventory_store_counting/add.vue'
                ),
            },

          // inventoryAdjustment

          {
            path: 'inventoryAdjustment',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_adjustment/index.vue'
              ),
          },
          {
            path: 'inventoryAdjustment/add',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_adjustment/add.vue'
              ),
          },
          {
            path: 'inventoryAdjustment/edit/:id',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_adjustment/add.vue'
              ),
          },

          // inventoryDamageStoreItems

          {
            path: 'inventoryDamageStoreItems',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_damage_store_items/index.vue'
              ),
          },
          {
            path: 'inventoryDamageStoreItems/add',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_damage_store_items/add.vue'
              ),
          },
          {
            path: 'inventoryDamageStoreItems/edit/:id',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_damage_store_items/add.vue'
              ),
          },

          // inventoryDirectTransfer

          {
            path: 'inventoryDirectTransfer',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_direct_transfer/index.vue'
              ),
          },
          {
            path: 'inventoryDirectTransfer/add',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_direct_transfer/add.vue'
              ),
          },
          {
            path: 'inventoryDirectTransfer/edit/:id',
            component: () =>
              import(
                '../views/admin/inventory/transactions/inventory_direct_transfer/add.vue'
              ),
          },

          //  print
          {
            path: 'print/:id',
            component: () =>
              import('../views/admin/inventory/transactions/print.vue'),
          },
        ],
      },
      // Users
      {
        path: 'users',
        component: () => import('../views/admin/users/users.vue'),
        children: [
          // roles
          {
            path: 'roles',
            component: () => import('../views/admin/users/roles/index.vue'),
          },
          {
            path: 'roles/add',
            component: () => import('../views/admin/users/roles/add.vue'),
          },
          {
            path: 'roles/edit/:id',
            component: () => import('../views/admin/users/roles/add.vue'),
          },

          // admins
          {
            path: 'admins',
            component: () => import('../views/admin/users/admins/index.vue'),
          },
          {
            path: 'admins/add',
            component: () => import('../views/admin/users/admins/add.vue'),
          },
          {
            path: 'admins/edit/:id',
            component: () => import('../views/admin/users/admins/add.vue'),
          },

          // clients
          {
            path: 'clients',
            component: () => import('../views/admin/users/clients/index.vue'),
          },
          {
            path: 'clients/add',
            component: () => import('../views/admin/users/clients/add.vue'),
          },
          {
            path: 'clients/edit/:id',
            component: () => import('../views/admin/users/clients/add.vue'),
          },
        ],
      },
      // Sales
      {
        path: 'sales',
        component: () => import('../views/admin/sales/sales.vue'),
        children: [
          // contracts
          {
            path: 'contracts',
            component: () => import('../views/admin/sales/contracts/index.vue'),
          },
          {
            path: 'contracts/add',
            component: () => import('../views/admin/sales/contracts/add.vue'),
          },
          {
            path: 'contracts/edit/:id',
            component: () => import('../views/admin/sales/contracts/add.vue'),
          },
          // collection
          {
            path: 'collection',
            component: () =>
              import('../views/admin/sales/collection/index.vue'),
          },
          // materials
          {
            path: 'materials',
            component: () => import('../views/admin/sales/materials/index.vue'),
          },
          {
            path: 'materials/add',
            component: () => import('../views/admin/sales/materials/add.vue'),
          },
          {
            path: 'materials/edit/:id',
            component: () => import('../views/admin/sales/materials/add.vue'),
          },

          // tasks
          {
            path: 'tasks',
            component: () => import('../views/admin/sales/tasks/index.vue'),
          },
          {
            path: 'tasks/add',
            component: () => import('../views/admin/sales/tasks/add.vue'),
          },
          {
            path: 'tasks/edit/:id',
            component: () => import('../views/admin/sales/tasks/add.vue'),
          },
          // tasksOrder
          {
            path: 'tasksOrder',
            component: () =>
              import('../views/admin/sales/tasks_order/index.vue'),
          },
          {
            path: 'tasksOrder/add',
            component: () => import('../views/admin/sales/tasks_order/add.vue'),
          },
          {
            path: 'tasksOrder/edit/:id',
            component: () => import('../views/admin/sales/tasks_order/add.vue'),
          },
          // tasksOrderDone
          {
            path: 'tasksOrderDone',
            component: () =>
              import('../views/admin/sales/tasks_order_done/index.vue'),
          },
          // purchases
          {
            path: 'purchases',
            component: () => import('../views/admin/sales/purchases/index.vue'),
          },
          {
            path: 'purchases/add',
            component: () => import('../views/admin/sales/purchases/add.vue'),
          },
          {
            path: 'purchases/edit/:id',
            component: () => import('../views/admin/sales/purchases/add.vue'),
          },
        ],
      },

      // technicalSupport
      {
        path: 'technicalSupport',
        component: () =>
          import('../views/admin/technical_support/technical_support.vue'),
        children: [
          // installations
          {
            path: 'installations',
            component: () =>
              import(
                '../views/admin/technical_support/installations/index.vue'
              ),
          },
          // distributions
          {
            path: 'distributions',
            component: () =>
              import(
                '../views/admin/technical_support/distributions/index.vue'
              ),
          },
          // operations
          {
            path: 'operations',
            component: () =>
              import('../views/admin/technical_support/operations/index.vue'),
          },
          // revisions
          {
            path: 'revisions',
            component: () =>
              import('../views/admin/technical_support/revisions/index.vue'),
          },
          // delivery
          {
            path: 'delivery',
            component: () =>
              import('../views/admin/technical_support/delivery/index.vue'),
          },
          // deliveryNotes
          {
            path: 'deliveryNotes',
            component: () =>
              import(
                '../views/admin/technical_support/deliveryNotes/index.vue'
              ),
          },
        ],
      },
      // secretary
      {
        path: 'secretary',
        component: () => import('../views/admin/secretary/secretary.vue'),
        children: [
          // welcome
          {
            path: 'welcome',
            component: () =>
              import('../views/admin/secretary/welcome/index.vue'),
          },
          // surveys
          {
            path: 'surveys',
            component: () =>
              import('../views/admin/secretary/surveys/index.vue'),
          },
          // circulars
          {
            path: 'circulars',
            component: () =>
              import('../views/admin/secretary/circulars/index.vue'),
          },
          {
            path: 'circulars/add',
            component: () =>
              import('../views/admin/secretary/circulars/add.vue'),
          },
        ],
      },
      // reports
      {
        path: 'reports',
        component: () => import('../views/admin/reports/reports.vue'),
        children: [
          // inventory

          // storeItems
          {
            path: 'inventoryStoreItems',
            component: () =>
              import('../views/admin/reports/inventory/store_items/index.vue'),
          },

          // storeItems
          {
            path: 'inventoryStoreItemsInfo',
            component: () =>
              import('../views/admin/reports/inventory/store_items/info.vue'),
          },

           // storeItems
           {
            path: 'inventoryStoreItemsInfoTwo',
            component: () =>
              import('../views/admin/reports/inventory/store_items/info2.vue'),
          },

          

          // storeItems
          {
            path: 'inventoryStoreItemsInfoW',
            component: () =>
              import('../views/admin/reports/inventory/store_items/infoW.vue'),
          },

          // openingBalance
          {
            path: 'inventoryBalance',
            component: () =>
              import(
                '../views/admin/reports/inventory/inventory_opening_balance/index.vue'
              ),
          },

          // openingBalance
          {
            path: 'openingBalance',
            component: () =>
              import(
                '../views/admin/reports/inventory/inventory_opening_balance.vue'
              ),
          },

          // inventoryDamageStoreItems
          {
            path: 'inventoryDamageStoreItems',
            component: () =>
              import(
                '../views/admin/reports/inventory/inventoryDamageStoreItems.vue'
              ),
          },

          // receivingInventory

          {
            path: 'receivingInventory',
            component: () =>
              import('../views/admin/reports/inventory/receivingInventory.vue'),
          },

          // inventoryStoreCounting

          {
            path: 'inventoryStoreCounting',
            component: () =>
              import(
                '../views/admin/reports/inventory/inventoryStoreCounting.vue'
              ),
          },

           // inventoryStoreCountingTwo

           {
            path: 'inventoryStoreCountingTwo',
            component: () =>
              import(
                '../views/admin/reports/inventory/info2.vue'
              ),
          },

          // inventory_store_counting_req

          {
            path: 'inventory_store_counting_req',
            component: () =>
              import(
                '../views/admin/reports/inventory/inventory_store_counting_req.vue'
              ),
          },

          

          // inventoryAdjustment

          {
            path: 'inventoryAdjustment',
            component: () =>
              import(
                '../views/admin/reports/inventory/inventoryAdjustment.vue'
              ),
          },

          // totalSales
          {
            path: 'sales/totalSales',
            component: () =>
              import('../views/admin/reports/sales/total_sales.vue'),
          },
          // clients
          {
            path: 'sales/clients',
            component: () => import('../views/admin/reports/sales/clients.vue'),
          },
          // contracts
          {
            path: 'sales/contracts',
            component: () =>
              import('../views/admin/reports/sales/contracts.vue'),
          },
          // contractsDeliveryDate
          {
            path: 'sales/contractsDeliveryDate',
            component: () =>
              import('../views/admin/reports/sales/contractsDeliveryDate.vue'),
          },
          // contractsBay
          {
            path: 'sales/contractsBay',
            component: () =>
              import('../views/admin/reports/sales/contractsBay.vue'),
          },
          // materials
          {
            path: 'sales/materials',
            component: () =>
              import('../views/admin/reports/sales/materials.vue'),
          },
          // purchases
          {
            path: 'sales/purchases',
            component: () =>
              import('../views/admin/reports/sales/purchases.vue'),
          },
          // tasksOrderDone
          {
            path: 'sales/tasksOrderDone',
            component: () =>
              import('../views/admin/reports/sales/tasks_order_done.vue'),
          },
          // collection
          {
            path: 'sales/collection',
            component: () =>
              import('../views/admin/reports/sales/collection.vue'),
          },
          // installations
          {
            path: 'sales/installations',
            component: () =>
              import('../views/admin/reports/sales/installations.vue'),
          },
          // distributions
          {
            path: 'sales/distributions',
            component: () =>
              import('../views/admin/reports/sales/distributions.vue'),
          },
          // tasks
          {
            path: 'sales/tasks',
            component: () => import('../views/admin/reports/sales/tasks.vue'),
          },
          // operations
          {
            path: 'sales/operations',
            component: () =>
              import('../views/admin/reports/sales/operations.vue'),
          },
          // revisions
          {
            path: 'sales/revisions',
            component: () =>
              import('../views/admin/reports/sales/revisions.vue'),
          },
          // delivery
          {
            path: 'sales/delivery',
            component: () =>
              import('../views/admin/reports/sales/delivery.vue'),
          },
          // deliveryNotes
          {
            path: 'sales/deliveryNotes',
            component: () =>
              import('../views/admin/reports/sales/deliveryNotes.vue'),
          },
          // welcome
          {
            path: 'sales/welcome',
            component: () => import('../views/admin/reports/sales/welcome.vue'),
          },
          // notifications
          {
            path: 'sales/notifications',
            component: () =>
              import('../views/admin/reports/sales/notifications.vue'),
          },
          // circulars

          {
            path: 'sales/circulars',
            component: () =>
              import('../views/admin/reports/sales/circulars.vue'),
          },
          // surveys
          {
            path: 'sales/surveys',
            component: () => import('../views/admin/reports/sales/surveys.vue'),
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: () => import('../views/admin/login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: 'print/:id',
    component: () =>
      import('../views/admin/inventory/transactions/printoutlayout.vue'),
  },
];
